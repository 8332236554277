import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import { Curve404 } from "../components/icons"
import { mq } from "../styles/variables"

const Wrapper = styled.div`
  max-width: 690px;
  position: relative;
  height: 73.529vw;
  margin: 0 0 120px;

  @media (min-width: 850px) {
    height: 625px;
  }

  @media ${mq.gte("l")} {
    margin: 40px 0 160px 75px;
  }
`

const Heading = styled.h1`
  font-size: 7.058vw;
  width: 7em;
  position: absolute;
  left: 45%;
  top: 63%;

  @media (min-width: 850px) {
    font-size: 60px;
  }
`

const Text404 = styled.div`
  font-size: 18.824vw;
  position: absolute;
  left: 10.459%;
  top: 2%;

  @media (min-width: 850px) {
    font-size: 160px;
  }
`

const StyledCurve404 = styled(Curve404)`
  width: 69.765vw;
  height: 73.529vw;
  position: absolute;

  @media (min-width: 850px) {
    width: 593px;
    height: 625px;
  }
`

const P = styled.p`
  position: absolute;
  right: 0;
  top: 110%;
  z-index: 1;

  @media ${mq.gte("s")} {
    top: auto;
    bottom: 0;
    right: 10%;
  }

  @media ${mq.gte("m")} {
    font-size: 22px;
  }
`

const P404 = ({ location }) => {
  return (
    <Layout color="#D9E3DE" title="Page not found" location={location}>
      <Wrapper>
        <Heading>Sorry, this page does not exist.</Heading>
        <Text404>404</Text404>
        <P>
          <Link to="/">Back to start page</Link>
        </P>
        <StyledCurve404 />
      </Wrapper>
    </Layout>
  )
}

export default P404
